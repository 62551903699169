var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"},[(_vm.isMobile && _vm.isIOS)?_c('vue-pull-refresh',{class:{
            refreshMobile: !_vm.isInstalled
        },attrs:{"on-refresh":_vm.onRefresh,"config":_vm.pullConfig}},[(_vm.taken.length)?_c('mobile',{attrs:{"data":_vm.taken,"loading":_vm.loading,"open":_vm.open,"showPrice":false},on:{"call":_vm.call}}):_vm._e(),_c('div',{class:{
                mtdefault: _vm.isInstalled,
                mtdefaultMobile: !_vm.isInstalled
            }},[_c('tips',{staticClass:"pl16 pr16 pb16",staticStyle:{"padding-top":"16px"}})],1)],1):_vm._e(),(_vm.isMobile && !_vm.isIOS)?_c('mobile',{attrs:{"data":_vm.taken,"loading":_vm.loading,"open":_vm.open,"showPrice":false},on:{"call":_vm.call}}):_vm._e(),(!_vm.isMobile && _vm.taken.length)?_c('desktop',{attrs:{"data":_vm.taken,"showPrice":false}}):_vm._e(),(!_vm.isIOS)?_c('div',{class:{
            mtdefault: _vm.isInstalled,
            mtdefaultMobile: !_vm.isInstalled
        }},[_c('tips',{staticClass:"pl16 pr16 pb16",staticStyle:{"padding-top":"16px"}})],1):_vm._e(),(_vm.showLoading)?_c('div',{staticClass:"loading-overlay"},[_c('div',{staticClass:"wrapper"},[(_vm.params.page >= 1)?_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-stroke":2}}):_vm._e()],1)]):_vm._e(),(_vm.isMounted)?_c('infinite-loading',{attrs:{"identifier":_vm.infiniteId,"infinite-scroll-disabled":"busy"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"spinner"},slot:"spinner"},[(_vm.taken.length)?_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-stroke":2,"md-diameter":30}}):_vm._e()],1),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }