<template>
    <div
        class="md-layout-item md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"
    >
        <vue-pull-refresh
            v-if="isMobile && isIOS"
            :on-refresh="onRefresh"
            :config="pullConfig"
            :class="{
                refreshMobile: !isInstalled
            }"
        >
            <mobile
                v-if="taken.length"
                :data="taken"
                :loading="loading"
                :open="open"
                @call="call"
                :showPrice="false"
            />

            <div
                :class="{
                    mtdefault: isInstalled,
                    mtdefaultMobile: !isInstalled
                }"
            >
                <tips class="pl16 pr16 pb16" style="padding-top:16px;" />
            </div>
        </vue-pull-refresh>
        <mobile
            v-if="isMobile && !isIOS"
            :data="taken"
            :loading="loading"
            :open="open"
            @call="call"
            :showPrice="false"
        />

        <desktop
            v-if="!isMobile && taken.length"
            :data="taken"
            :showPrice="false"
        />

        <div
            v-if="!isIOS"
            :class="{
                mtdefault: isInstalled,
                mtdefaultMobile: !isInstalled
            }"
        >
            <tips class="pl16 pr16 pb16" style="padding-top:16px;" />
        </div>

        <div class="loading-overlay" v-if="showLoading">
            <div class="wrapper">
                <md-progress-spinner
                    v-if="params.page >= 1"
                    md-mode="indeterminate"
                    :md-stroke="2"
                />
            </div>
        </div>

        <infinite-loading
            v-if="isMounted"
            @infinite="infiniteHandler"
            :identifier="infiniteId"
            infinite-scroll-disabled="busy"
        >
            <div slot="spinner">
                <md-progress-spinner
                    v-if="taken.length"
                    md-mode="indeterminate"
                    :md-stroke="2"
                    :md-diameter="30"
                />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
const VuePullRefresh = require('vue-pull-refresh');
import Mobile from '../components/Home/mobile';
import Desktop from '../components/Home/desktop';
import Tips from '../components/elements/Tips';

export default {
    components: {
        'vue-pull-refresh': VuePullRefresh,
        Mobile,
        Desktop,
        Tips
    },

    data() {
        return {
            taken: [],
            isMounted: false,
            showLoading: true,
            loading: false,
            open: false,
            infiniteId: +new Date(),
            params: {
                page: 1,
                'per-page': 10
            },
            pullConfig: {
                errorLabel: '',
                startLabel: '',
                readyLabel: '',
                loadingLabel: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            currentUser: 'auth/currentUser',
            meta: 'requests/takenPassengersMeta'
        }),

        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        },

        isInstalled() {
            return window.navigator.standalone === true;
        },

        isIOS() {
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        }
    },

    methods: {
        ...mapMutations({
            counterAdd: 'auth/COUNTER_ADD'
        }),

        ...mapActions({
            getCarrier: 'auth/validate',
            takenPassengers: 'requests/takenPassengers',
            getPhone: 'content/getPhone'
        }),

        async onRefresh() {
            this.params = {
                page: 1,
                'per-page': 10
            };

            await this.featch(true);

            return new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve();
                }, 1000);
            });
        },

        async call(e) {
            this.loading = true;
            const response = await this.getPhone(e.uuid);

            if (response) {
                this.loading = false;
                location.href = 'tel:' + response.phone;
            }
        },

        infiniteHandler($state) {
            setTimeout(async () => {
                if (
                    this.meta.pageCount > this.params.page &&
                    this.meta.pageCount <= 10
                ) {
                    this.params.page += 1;
                    await this.featch();
                    $state.loaded();
                } else {
                    $state.complete();
                }
            }, 500);
        },

        async featch(clear = false) {
            const params = this.params;

            try {
                const data = await this.takenPassengers({ params });

                if (clear) {
                    setTimeout(() => {
                        this.taken = [];
                        this.taken.push(...data.connections);
                    }, 100);
                } else {
                    this.taken.push(...data.connections);
                }
            } catch (error) {
                console.error(error);
                this.$toast.error(this.$t('global.somethingWrong'));
            }
        },

        changeType() {
            this.params.page = 1;
            this.infiniteId += 1;
        },

        scrollToTop() {
            window.scrollTo(0, 0);
        },

        async handleVisibilityChange() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                ) &&
                document.visibilityState === 'visible'
            ) {
                this.showLoading = true;

                try {
                    this.taken = [];
                    await this.featch();
                    this.showLoading = false;
                } catch (error) {
                    this.$toast.error(this.$t('global.somethingWrong'));
                    this.showLoading = false;
                }
            }
        }
    },

    async beforeDestroy() {
        this.isMounted = false;
        window.removeEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );
    },

    async mounted() {
        await this.getCarrier();
        await this.featch();

        this.showLoading = false;

        this.scrollToTop();

        if (localStorage.openingCounter < 5 && this.isMobile) {
            this.open = true;

            setTimeout(() => {
                this.open = false;
            }, 1000);

            this.counterAdd();
        }

        this.isMounted = true;

        window.addEventListener(
            'visibilitychange',
            this.handleVisibilityChange
        );
    }
};
</script>
